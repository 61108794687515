import { HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild, Input } from '@angular/core';
import { MessageService, TreeNode } from 'primeng/api';
import { CustomTreeNode } from '../treeview/CustomTreeNode';
import { CommonService } from '../services/common/common.service';
import { Tree } from 'primeng/tree';
import { Year } from '../../model/year';
import { LocalService } from '../services/local.service';
import { Constant } from '../../model/constant.enums';
import { Context } from '../../model/context';
import { documentsService } from '../services/documents.service';
import { messages } from '../messages/message.constant';
import { ObjectUtils } from 'primeng/utils';
import { MsalService } from '@azure/msal-angular';
import { CheckboxModule } from 'primeng/checkbox';

const TREE_DATA: TreeNode[] = [];

type nodeSearchArgs = {
  key: string,
  searchFields: string[],
  filterText: any[],
  isStrictMode: boolean
}

@Component({
  selector: 'app-treeview',
  templateUrl: './treeview.component.html',
  styleUrls: ['./treeview.component.css'],
  providers: [MessageService],
})
export class TreeviewComponent implements OnInit {

  constructor(private messageService: MessageService, private commonService: CommonService,
    private localService: LocalService, private documentService: documentsService,
    private authService: MsalService) {
  }

  activeAccount!: any;
  username!: string;
  parsedContext!: Context;

  engagementYears: any = [];

  workflowYears: any = [];

  selectedEngagementYears: Year | undefined;

  selectedWorkflowYears: Year | undefined;

  files: TreeNode[] = TREE_DATA;

  originalFiles: TreeNode[] = TREE_DATA;

  treeval: string = '';

  owner: string = '';

  filterBy: keyof CustomTreeNode = 'fullLabel';

  filterLocale: string | undefined;

  filterMode: string = 'lenient';

  filterCriteria: any = { labeltext: [], engagementtaxyear: [], workflowtaxyear: [] };

  // @ts-ignore: Object is possibly 'null'.
  selectedFile: TreeNode | undefined
  // @ts-ignore: Object is possibly 'null'.
  @ViewChild('tt') tree: Tree;

  isTreeLoading: boolean = false;
  showOnlyActiveEngagements: boolean = true;
  showOnlyActiveWorkflows: boolean = false;

  ngOnInit() {
    this.files = [];
    this.activeAccount = this.authService?.instance?.getActiveAccount();
    this.username = this.activeAccount?.username;
    this.setContext();
    this.nodeSelectOnBreadcrumb();
    this.refreshComponent();

    const currentYear: number = new Date().getFullYear();

    for (let year = currentYear + 5; year >= currentYear - 25; year--) {
      this.engagementYears.push({ name: year.toString(), code: year.toString() + ' - Engagement' });
      this.workflowYears.push({ name: year.toString(), code: year.toString() + ' - Workflow' });
    }
  }

  private setContext() {
    const treecontext = this.localService.getData(Constant[Constant.context]);
    this.parsedContext = JSON.parse(treecontext ?? '');
    this.getDocumentsTree();
  }

  getDocumentsTree() {
    let parameters = new HttpParams();
    parameters = parameters.append('recId', this.parsedContext.recId.toLocaleLowerCase());
    parameters = parameters.append('recType', this.parsedContext.recType.toLocaleLowerCase());
    parameters = parameters.append('clientId', this.parsedContext.clientId.toLocaleLowerCase());
    parameters = parameters.append('username', this.username.toLocaleLowerCase());
    parameters = parameters.append('showOnlyActiveEngagements', this.showOnlyActiveEngagements);
    parameters = parameters.append('showOnlyActiveWorkflows', this.showOnlyActiveWorkflows);
    this.isTreeLoading = true;
    this.documentService.getDocumentsList('getdocumentstree', parameters)
      .subscribe({
        next: (result) => {
          console.log(result);
          this.originalFiles = result.documentTree;
          this.files = this.originalFiles;
          this.owner = result.owner;
          this.parsedContext.owner = this.owner;
          this.localService.saveData(Constant[Constant.context], JSON.stringify(this.parsedContext.owner));
          setTimeout(() => {
            // @ts-ignore: Object is possibly 'null'.
            this.selectedFile = this.findSelectableNode(this.originalFiles, this.parsedContext.recId);
            this.nodeSelect({ node: this.selectedFile });
          }, 500);
          this.isTreeLoading = false;
        },
        error: (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedTreeView });
          console.error(error);
          this.isTreeLoading = false;
        }
      });
  }

  nodeSelect(evt: any): void {
    let navigationNodes = [];
    let node = evt.node;
    while (node.parent) {
      navigationNodes.push(node);
      node = node.parent;
    }
    navigationNodes.push(node);

    let nodes: string[] = this.getAllNodes(evt.node)
      .filter(value => value.icon?.includes("pi-file"))
      .map(value => value.key ?? "");

    const context = {
      recId: navigationNodes[0].recordId,
      recType: this.getRecTypeFromType(navigationNodes[0].type),
      clientId: node.recordId,
      clientName: node.label,
      type: navigationNodes[0].type,
      owner: this.owner
    }
    this.localService.saveData(Constant[Constant.context], JSON.stringify(context));
    this.commonService.refreshDocSelectionOnNodeClick(true);
    this.commonService.nodeClicked(navigationNodes);
    this.commonService.clickedNodeToSignOff(nodes);
  }

  getAllNodes<T>(node: TreeNode<T>): TreeNode<T>[] {
    let nodes: TreeNode<T>[] = [];
    nodes.push(node);

    if (node.children) {
      for (const child of node.children) {
        nodes = nodes.concat(this.getAllNodes(child));
      }
    }

    return nodes;
  }

  //filterData(filter: any): void {
  //  if (!filter.target.value) {
  //    // if there's no filter, show all nodes
  //    this.files = this.originalFiles;
  //    return;
  //  }

  //  this.tree._filter(filter.target.value);
  //}

  private findSelectableNode(nodes: CustomTreeNode[], key: string): TreeNode | null {
    for (const node of nodes) {
      if (node.recordId?.toLocaleLowerCase() === key.toLocaleLowerCase()) {
        return node;
      }

      if (node.children != null) {
        const foundNode = this.findSelectableNode(node.children, key);
        if (foundNode != null) {
          return foundNode;
        }
      }
    }
    return null;
  }

  private findSelectableNodeByKeyForBreadcrumb(nodes: CustomTreeNode[], key: string): CustomTreeNode | null {
    for (const node of nodes) {
      if (node.key?.toLocaleLowerCase() === key.toLocaleLowerCase()) {
        return node;
      }

      if (node.children != null) {
        const foundNode = this.findSelectableNodeByKeyForBreadcrumb(node.children, key);
        if (foundNode != null) {
          return foundNode;
        }
      }
    }
    return null;
  }


  getRecTypeFromType(type: string): string {
    let recType = "";
    if (type == 'client') {
      recType = 'account';
    }
    else if (type == 'taxpayer') {
      recType = 'contact';
    }
    else if (type == 'taxyear') {
      recType = 'taxyear';
    }
    else if (type == 'engagement') {
      recType = 'mcs_engagement';
    }
    else if (type == 'workflow') {
      recType = 'msdyn_project';
    }
    return recType;
  }

  refreshComponent() {
    this.commonService.refreshTreeOnOperationCompleteEvent.subscribe((val: boolean) => {
      if (val == true) {
        //this.getDocumentsTree(this.parsedContext.recId, this.parsedContext.recType, this.parsedContext.clientId, this.parsedContext.clientName);
        //this.selectedCustomers = [];
      }
    });
  }

  // Function to make node selected on click of breadcrumb
  nodeSelectOnBreadcrumb() {
    this.commonService.breadcrumbClickedEvent.subscribe((breadcrumbItemId: string) => {
      setTimeout(() => {
        this.selectedFile = this.findSelectableNodeByKeyForBreadcrumb(this.originalFiles, breadcrumbItemId) ?? undefined;
        this.nodeSelect({ node: this.selectedFile });
      }, 500);
    });
  }

  filterTree(searchValue: string[], type: string) {
    this.filterCriteria[type] = this.checkInputArrayValueToBeNullOrEmpty(searchValue);
    if (this.filterCriteria.labeltext.length <= 0 &&
      this.filterCriteria.engagementtaxyear.length <= 0 &&
      this.filterCriteria.workflowtaxyear.length <= 0) {
      this.files = this.originalFiles;
    } else {
      let filterInputNodes = this.originalFiles;
      let tempFilteredNodes: TreeNode<any>[] = [];
      const searchFields: string[] = this.filterBy.split(',');
      const isStrictMode = this.filterMode === 'strict';

      for (let [key, value] of Object.entries(this.filterCriteria)) {
        if ((value && Array.isArray(value) && value.length > 0)) {
          const valueArray = Array.isArray(value) ? new Array(...value) : [value];
          const filterText = valueArray.map((x: string) => ObjectUtils.removeAccents(x).toLocaleLowerCase(this.filterLocale));
          tempFilteredNodes = [];
          let paramsWithoutNode: nodeSearchArgs = { key, searchFields, filterText, isStrictMode };
          for (let node of <TreeNode<any>[]>filterInputNodes) {
            let copyNode = { ...node };
            if (
              (isStrictMode && (this.findFilteredNodes(copyNode, paramsWithoutNode) || this.isFilterMatched(copyNode, paramsWithoutNode))) ||
              (!isStrictMode && (this.isFilterMatched(copyNode, paramsWithoutNode) || this.findFilteredNodes(copyNode, paramsWithoutNode)))
            ) {
              tempFilteredNodes.push(copyNode);
            }
          }
          filterInputNodes = tempFilteredNodes;
        }
      }
      this.files = tempFilteredNodes;
    }
  }

  // find filtered nodes.
  findFilteredNodes(node: TreeNode, paramsWithoutNode: nodeSearchArgs) {
    if (node) {
      let matched = false;
      if (node.children) {
        let childNodes = [...node.children];
        node.children = [];
        for (let childNode of childNodes) {
          let copyChildNode = { ...childNode };
          if (this.isFilterMatched(copyChildNode, paramsWithoutNode)) {
            matched = true;
            node.children.push(copyChildNode);
          }
        }
      }

      if (matched) {
        node.expanded = true;
        return true;
      }
    }

    return false;
  }

  // check if filter criteria matches.
  isFilterMatched(node: TreeNode, params: nodeSearchArgs) {
    let { key, searchFields, filterText, isStrictMode } = params;
    let matched = false;
    if (key === 'labeltext' || key === node.type) {
      for (let field of searchFields) {
        let fieldValue = ObjectUtils.removeAccents(String(ObjectUtils.resolveFieldData(node, field))).toLocaleLowerCase(this.filterLocale);
        if (Array.isArray(filterText)) {
          if (filterText.filter((x: any) => fieldValue.indexOf(x) > -1).length > 0) {
            matched = true;
          }
        }
        else {
          if (fieldValue.indexOf(filterText) > -1) {
            matched = true;
          }
        }
      }
    }



    if (!matched || (isStrictMode && !this.isNodeLeaf(node))) {
      matched = this.findFilteredNodes(node, params) || matched;
    }

    return matched;
  }

  // check if node is a leaf node.
  isNodeLeaf(node: TreeNode): boolean {
    return node.leaf == false ? false : !(node.children && node.children.length);
  }

  private checkInputArrayValueToBeNullOrEmpty(values: any[]) {
    if (values?.length > 0) {
      return values.filter(function (x: any) {
        return x !== undefined && x !== null && x !== "";
      });
    }

    return values;
  }

  filterTreeFromMultiSelect(event: any, type: string) {
    this.filterTree(event.value.map((x: Year) => x.code), type);
  }

  filterTreeBasedOnActiveEngagements(event: any) {
    this.showOnlyActiveEngagements = event.checked;
    this.getDocumentsTree();
  }

  filterTreeBasedOnActiveWorkflows(event: any) {
    this.showOnlyActiveWorkflows = event.checked;
    this.getDocumentsTree();
  }
}
