import { AfterViewInit, Component, Inject, Input, Output, ViewChild, EventEmitter , OnInit} from '@angular/core';
import { Table } from 'primeng/table';
import { CommonService } from '../services/common/common.service';
import { SignOffMatrix } from '../../model/signoffmatrix';
import { documentsService } from '../services/documents.service';
import { LocalService } from '../services/local.service';
import { Constant } from '../../model/constant.enums';
import { Context } from '../../model/context';
import { DocumentTreeRequest } from '../../model/documentTreeRequest';
import { SharePointGraphService } from '../services/sharepointgraph.service';
import { ContentSearchDTO } from '../../model/contentSearchDTO';
import { MessageService } from 'primeng/api';
import { messages } from '../messages/message.constant';
import { ChangeToInitials } from '../pipe/changetoinitials';
import { saveAs } from 'file-saver';
import { DownloadDocumentRequest } from '../../model/downloadDocumentRequest';
import { ConfigurationService } from '../services/configuration.service';
import { MsalService } from '@azure/msal-angular';
import Utilities from '../services/utilities'
import { FilterCriteria } from '../../model/filterCriteria';
import { SortCriteria } from '../../model/sortCriteria';
import { FilterMatchMode } from 'primeng/api';

let ELEMENT_DATA: SignOffMatrix[]

@Component({
  selector: 'app-matrix',
  templateUrl: './matrix.component.html',
  styleUrls: ['./matrix.component.css'],
  providers: [MessageService],
})
export class MatrixComponent implements OnInit {

  constructor(private commonService: CommonService, private documentService: documentsService,
    private localService: LocalService, private sharepointgraphService: SharePointGraphService, private messageService: MessageService,
    private configurationService: ConfigurationService, private authService: MsalService) {
  }
  invalidOperationReasons: string[] = ['Pending Upload', 'Pending Metadata Update', 'Archival In Progress', 'Unarchival In Progress', 'Hold In Progress', 'Unhold In Progress', 'Error In Upload'];
  uddChangeDocument: string[] = [];
  @Input()
  isEditMetadataAllowed: boolean = false;
  activeAccount!: any;
  username!: string;
  parsedContext!: Context;
  @Input()
  isApplicableTaxYearRequired: boolean | undefined = false;
  customers: SignOffMatrix[] = ELEMENT_DATA;
  originalcustomers: SignOffMatrix[] = [];

  filteredCustomers: SignOffMatrix[] = ELEMENT_DATA;

  selectedCustomers: SignOffMatrix[] = [];

  doctypedescordoctype2nddesc: string = '';

  highlightKey: string[] = [];

  isSignOffMatrixDataLoading: boolean = false;

  disableRowSelection: boolean = false;

  signOffMatrixRequest: DocumentTreeRequest | undefined;

  searchInput!: any;

  signOffMatrixCount: number = 25;
  currentPage = 1;
  hasMoreRecords = false;
  totalRecords = 0;
  isInitialLoad = true;
  previousFilters: any = {}; // To store previous filter state
  recentfilterCriteriaArray: FilterCriteria[] = [];
  sortCriteria: SortCriteria = {
    sortField: "",
    sortOrder: 0
  }
  previousSortCriteria: any;

  // Define the available row counts
  rowCounts = [
    { label: '10', value: 10 },
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
    { label: '200', value: 200 },
    { label: '500', value: 500 }
  ];

  customDateFilterOptions: any[] = [
    { label: 'Date is', value: FilterMatchMode.DATE_IS },            // Equals
    { label: 'Date on or Before', value: FilterMatchMode.DATE_BEFORE },     // Less than or equal to
    { label: 'Date on or After', value: FilterMatchMode.DATE_AFTER }       // Greater than or equal to
  ];

  @Input()
  set saveGridInformation(trigger: boolean) {
    if (trigger) {
      this.saveGridInformationfromMatrix();
    }
  }

  @Output()
  saveCompleteEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  documentUddUpdateEvent: EventEmitter<any> = new EventEmitter<any>()

  @ViewChild('dt1') dt: Table | undefined;

  ngOnInit() {
    this.customers = [];
    this.filteredCustomers = [];
    this.disableRowSelection = false;
    this.setContext();
    this.nodeSubscription();
    this.disableRowSelectionSubscription();
    this.getSignOffMatrixRowCount();
    this.getDocumentsTreeForSelectedNode();
    this.setResetSpinner();
    this.refreshComponent();
    this.refreshSelection();
    this.activeAccount = this.authService?.instance?.getActiveAccount();
    this.username = this.activeAccount?.username;
  }

  // Get Sign Off matrix count.
  private getSignOffMatrixRowCount() {
    this.configurationService.getSignOffMatrixRowCount().subscribe({
      next: (response) => {
        this.signOffMatrixCount = response;
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedFetch });
        console.error(error);
      }
    });
  }


  private nodeSubscription() {
    this.commonService.nodeClickedSubscription
      .subscribe((data: string[]) => {
        if (data.length !== 0 && typeof (ELEMENT_DATA) !== 'undefined') {
          this.customers = ELEMENT_DATA;
          let matchedCustomers = this.customers.filter(function (customer) {
            return data.includes(customer.key);
          });
          this.customers = matchedCustomers;
          this.originalcustomers = JSON.parse(JSON.stringify(matchedCustomers));
          this.filteredCustomers = matchedCustomers;
        }
      });
  }

  private setContext() {
    const treecontext = this.localService.getData(Constant[Constant.context]);
    this.parsedContext = JSON.parse(treecontext ?? '');
  }

  private getSignOffMatrixData() {
    const filterpipe = new ChangeToInitials();
    if (this.signOffMatrixRequest !== undefined) {
      this.searchInput = "";
      this.isSignOffMatrixDataLoading = true;
      this.commonService.buttonDisableForDataLoading(true);
      this.signOffMatrixRequest.pageNumber = this.currentPage;
      this.signOffMatrixRequest.pageSize = this.signOffMatrixCount;
      this.signOffMatrixRequest.filters = this.recentfilterCriteriaArray;
      this.signOffMatrixRequest.sortCriteria = this.sortCriteria;
      this.documentService.getDocumentTreeForSelectedNode(this.signOffMatrixRequest).subscribe({
        next: (result: any) => {
          this.hasMoreRecords = result.moreRecords;
          this.totalRecords = result.totalCount;
          let mappedData = result.documents.map((item: any) => {
            return {
              position: item.mcsHierarchyWorkflow,
              document: item.mcsName,
              documentuid: item.mcsDocumentUid,
              taskname: item.mcsTaskName,
              userdefineddescription: item.mcsUserDefinedDescription,
              preparer: item.mcsPreparer,
              detailreviewer: item.mcsDetailReviewer,
              substantivereviewer: item.mcsSubstantiveReviewer,
              requiredreviewer: item.mcsRequiredReviewer,
              signer: item.mcsSigner,
              officeadmin: item.mcsOfficeAdmin,
              projectmanager: item.mcsProjectManager,
              signersignoffdate: item.mcsSignerSignOffDate == '0001-01-01T00:00:00' ? null : item.mcsSignerSignOffDate,
              preparersignoffdate: item.mcsPreparerSignOffDate == '0001-01-01T00:00:00' ? null : item.mcsPreparerSignOffDate,
              detailreviewersignoffdate: item.mcsDetailReviewerSignOffDate == '0001-01-01T00:00:00' ? null : item.mcsDetailReviewerSignOffDate,
              substantivereviewersignoffdate: item.mcsSubstantiveReviewerSignOffDate == '0001-01-01T00:00:00' ? null : item.mcsSubstantiveReviewerSignOffDate,
              requiredreviewersignoffdate: item.mcsRequiredReviewerSignOffDate == '0001-01-01T00:00:00' ? null : item.mcsRequiredReviewerSignOffDate,
              officeadminsignoffdate: item.mcsOfficeAdminSignOffDate == '0001-01-01T00:00:00' ? null : item.mcsOfficeAdminSignOffDate,
              projectmanagersignoffdate: item.mcsProjectManagerSignOffDate == '0001-01-01T00:00:00' ? null : item.mcsProjectManagerSignOffDate,
              senttogpdate: item.mcsSentToGlobalPortalDate == '0001-01-01T00:00:00' ? null : item.mcsSentToGlobalPortalDate,
              applicabletaxyear: item.mcsApplicableTaxYear,
              senttosureprepdate: item.mcsSentToSurePrepDate == '0001-01-01T00:00:00' ? null : item.mcsSentToSurePrepDate,
              lastmodifieddate: item.mcsDocLastModified == '0001-01-01T00:00:00' ? null : item.mcsDocLastModified,
              doctypedescordoctype2nddesc: item.mcsDoctype2ndDesc != '' ? item.mcsDoctype2ndDesc : item.mcsDoctypeDesc,
              market: item.mcsMarket,
              region: item.mcsRegion,
              key: item.mcsDocumentId,
              url: item.mcsDocUrl,
              doctype: item.mcsDoctype,
              doctypedescription: item.mcsDoctypeDesc,
              doctype2nddescription: item.mcsDoctype2ndDesc,
              statuscode: item.statusCode,
              retentionlabel: item.mcsRetentionLabel,
              doccheckoutstatus: item.mcsDocCheckOutStatus,
              nativeAppUrl: item.mcsNativeAppUrl ? item.mcsNativeAppUrl : item.mcsDocUrl,
              taxpayer: item.mcsHierarchyTaxpayer,
              taxpayerNumber: item.mcsTaxpayerNumber,
              engagement: item.mcsHierarchyEngagement,
              engagementnumber: item.mcsEngagementNumber,
              workflow: item.mcsHierarchyWorkflow,
              year: item.mcsEngagementTaxYear,
              workflowyear: item.mcsWorkflowTaxYear,
              retentionLabelAppliedOn: item.mcsRetentionLabelAppliedOn == '0001-01-01T00:00:00' ? null : item.mcsRetentionLabelAppliedOn,
              modifiedOn: item.modifiedOn == '0001-01-01T00:00:00' ? null : item.modifiedOn,
              preparerpipe: filterpipe.transform(item.mcsPreparer, item.mcsPreparerSignOffDate == '0001-01-01T00:00:00' ? null : item.mcsPreparerSignOffDate),
              detailreviewerpipe: filterpipe.transform(item.mcsDetailReviewer, item.mcsDetailReviewerSignOffDate == '0001-01-01T00:00:00' ? null : item.mcsDetailReviewerSignOffDate),
              substantivereviewerpipe: filterpipe.transform(item.mcsSubstantiveReviewer, item.mcsSubstantiveReviewerSignOffDate == '0001-01-01T00:00:00' ? null : item.mcsSubstantiveReviewerSignOffDate),
              requiredreviewerpipe: filterpipe.transform(item.mcsRequiredReviewer, item.mcsRequiredReviewerSignOffDate == '0001-01-01T00:00:00' ? null : item.mcsRequiredReviewerSignOffDate),
              signerpipe: filterpipe.transform(item.mcsSigner, item.mcsSignerSignOffDate == '0001-01-01T00:00:00' ? null : item.mcsSignerSignOffDate),
              officeadminpipe: filterpipe.transform(item.mcsOfficeAdmin, item.mcsOfficeAdminSignOffDate == '0001-01-01T00:00:00' ? null : item.mcsOfficeAdminSignOffDate),
              projectmanagerpipe: filterpipe.transform(item.mcsProjectManager, item.mcsProjectManagerSignOffDate == '0001-01-01T00:00:00' ? null : item.mcsProjectManagerSignOffDate),
              rollforward: item.mcsRollforward === "861570000" ? "Yes" : "No",
              isAssociated: item.isAssociated,
              isPrimary: item.isPrimary,
            };
          });

          ELEMENT_DATA = mappedData;
          this.customers = ELEMENT_DATA;
          this.originalcustomers = JSON.parse(JSON.stringify(ELEMENT_DATA));
          this.filteredCustomers = ELEMENT_DATA;
          this.isSignOffMatrixDataLoading = false;
          this.commonService.buttonDisableForDataLoading(false);
        },
        error: (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedSignOffMatrix });
          console.error(error);
          this.isSignOffMatrixDataLoading = false;
        }
      });
    }
  }

  onNextPage(): void {
    if (this.hasMoreRecords) {
      this.currentPage++;
      this.getSignOffMatrixData();
    }
  }

  onPreviousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.getSignOffMatrixData();
    }
  }

  onRowCountChange(count: number): void {
    this.signOffMatrixCount = count;
    this.currentPage = 1;  // Reset to the first page
    this.getSignOffMatrixData();
  }

  onPageChange(event: any): void {
    this.currentPage = event.page;
    this.signOffMatrixCount = event.rows;
    this.getSignOffMatrixData();
  }

  private getDocumentsTreeForSelectedNode() {
    this.commonService.getDocumentTreeOnNodeEvent.subscribe((request: DocumentTreeRequest) => {
      this.currentPage = 1;
      this.hasMoreRecords = false;
      this.totalRecords = 0;
      this.signOffMatrixRequest = request;
      this.getSignOffMatrixData();
    });
  }

  onFilter(event: any) {
    // Check if the new filters are different from the previous filters
    if (!this.areFiltersChanged(event.filters)) {
      return; // No changes detected, do nothing
    }

    this.currentPage = 1;
    this.hasMoreRecords = false;
    this.totalRecords = 0;
    this.convertFiltersToCriteria(event.filters);

    this.getSignOffMatrixData();
    // Update the previous filter state
    this.previousFilters = JSON.parse(JSON.stringify(event.filters));
  }

  areFiltersChanged(newFilters: any): boolean {
    // Compare new filters with previous filters
    return JSON.stringify(newFilters) !== JSON.stringify(this.previousFilters);
  }

  // Helper function to convert event filters to FilterCriteria[]
  convertFiltersToCriteria(filters: any): FilterCriteria[] {
    const filterCriteriaArray: FilterCriteria[] = [];

    // Iterate over the filter object to create an array of FilterCriteria
    for (const field in filters) {
      if (filters.hasOwnProperty(field) && filters[field].value) {
        filterCriteriaArray.push({
          field: field,
          matchMode: filters[field].matchMode || 'contains', // Default match mode to 'contains' if not specified
          value: filters[field].value
        });
      }
    }

    this.recentfilterCriteriaArray = filterCriteriaArray;
    return filterCriteriaArray;
  }

  onSort(event: any) {
    //const sort : SortCriteria = {
    //  sortField: event.field,
    //  sortOrder: event.order
    //};
    // Check if the new filters are different from the previous filters
    if (!this.isSortCriteriaChanged(event)) {
      return; // No changes detected, do nothing
    }
    this.sortCriteria = {
      sortField: event.field,
      sortOrder: event.order
    };

    this.getSignOffMatrixData();
    // Update the previous filter state
    this.previousSortCriteria = JSON.stringify(event);
  }

  isSortCriteriaChanged(event: any): boolean {
    // Compare new filters with previous filters
    return JSON.stringify(event) !== this.previousSortCriteria;
  }

  onUddInputChange(event: any) {
    if (event?.key) {
        if (!this.uddChangeDocument) {
          this.uddChangeDocument = [];
      }

      const originalValue = this.originalcustomers.find(x => x.key === event.key);

      if (originalValue?.userdefineddescription !== event.userdefineddescription) {
        if (this.uddChangeDocument.findIndex(x => x === event.key)) {
          this.uddChangeDocument.push(event.key);
          const documents = this.filteredCustomers.filter(x => this.uddChangeDocument.indexOf(x.key) > -1).map(x => { return x });
          this.documentUddUpdateEvent.emit(documents)
        }
      }
    }
  }

  clear(table: Table) {
    table.clear();
  }

  applyFilterGlobal($event: any, stringVal: any) {
    this.dt!.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }


  refreshComponent() {
    this.commonService.refreshOnOperationCompleteEvent.subscribe((val: boolean) => {
      if (val == true) {
        this.getSignOffMatrixData();
        this.commonService.refreshDocSelectionOnNodeClick(true);
      }
    });
  }

  isSignoffDateChanged(lastmodifieddate: Date, preparersignoffdate: Date, detailreviewersignoffdate: Date, signersignoffdate: Date, officeadminsignoffdate: Date, projectmanagersignoffdate: Date, key: string): boolean {
    if (lastmodifieddate != null && ((preparersignoffdate != null && detailreviewersignoffdate != null) || signersignoffdate != null || officeadminsignoffdate != null || projectmanagersignoffdate != null)) {
      const lastModifiedDate = this.getLocalTimeFromUTCTime(lastmodifieddate);
      const preparerSignOffDate = preparersignoffdate != null ? this.getLocalTimeFromUTCTime(preparersignoffdate) : preparersignoffdate;
      const detailReviewerSignOffDate = detailreviewersignoffdate != null ? this.getLocalTimeFromUTCTime(detailreviewersignoffdate) : detailreviewersignoffdate;
      const signerSignOffDate = signersignoffdate != null ? this.getLocalTimeFromUTCTime(signersignoffdate) : signersignoffdate;
      const officeAdminSignOffDate = officeadminsignoffdate != null ? this.getLocalTimeFromUTCTime(officeadminsignoffdate) : officeadminsignoffdate;
      const projectManagerSignOffDate = projectmanagersignoffdate != null ? this.getLocalTimeFromUTCTime(projectmanagersignoffdate) : projectmanagersignoffdate;
      const higherTime = this.getMaxDate(preparerSignOffDate, detailReviewerSignOffDate, signerSignOffDate, officeAdminSignOffDate, projectManagerSignOffDate).getTime();

      if (higherTime != null) {
        var result = lastModifiedDate.getTime() > higherTime;
        if (result == true) {
          this.highlightKey.push(key);
        }
        return result;
      }
      return false;
    }
    else return false;
  }

  // Convert UTC time to local time.
  getLocalTimeFromUTCTime(dateTimeInput: any): Date {
    if (dateTimeInput.endsWith('Z')) {
      const dateWithZ = new Date(dateTimeInput);
      return new Date(dateWithZ.getTime() + dateWithZ.getTimezoneOffset() * 60000); // Adding timezone offset in minutes.
    }
    return new Date(dateTimeInput);
  }

  shouldHighlightRow(customerKey: string): boolean {
    return this.highlightKey.includes(customerKey);
  }

  getMaxDate(preparersignoffdate: Date | null, detailreviewersignoffdate: Date | null, signersignoffdate: Date | null, officeadminsignoffdate: Date | null, projectmanagersignoffdate: Date | null): Date {
    if (preparersignoffdate === null && detailreviewersignoffdate === null && signersignoffdate === null && officeadminsignoffdate === null && projectmanagersignoffdate === null) {
      return new Date(0);
    }
    let max: Date = new Date(0);
    if (preparersignoffdate != null && detailreviewersignoffdate != null) {
      max = preparersignoffdate.getTime() > detailreviewersignoffdate.getTime() ? preparersignoffdate : detailreviewersignoffdate;
    }
    if (signersignoffdate !== null && signersignoffdate.getTime() > max.getTime()) {
      max = signersignoffdate;
    }
    if (officeadminsignoffdate !== null && officeadminsignoffdate.getTime() > max.getTime()) {
      max = officeadminsignoffdate;
    }
    if (projectmanagersignoffdate !== null && projectmanagersignoffdate.getTime() > max.getTime()) {
      max = projectmanagersignoffdate;
    }
    return max;
  }

  onRowSelect(event: any) {
    this.selectedCustomers = this.selectedCustomers.filter(x => x.isPrimary == true);
    this.commonService.rowSelected(this.selectedCustomers);
    this.commonService.rowSelectUnselect(true);
  }

  onRowUnselect(event: any) {
    this.commonService.rowSelected(this.selectedCustomers);
    this.commonService.rowSelectUnselect(true);
  }

  onHeaderCheckboxToggle(event: any) {
    if (event.checked) {
      this.selectedCustomers = this.selectedCustomers.filter(x => x.isPrimary == true);
      this.commonService.rowSelected(this.selectedCustomers);
    }
    else {
      this.commonService.rowSelected(this.selectedCustomers);
    }
  }

  refreshSelection() {
    this.commonService.refreshDocSelectionEvent.subscribe((val: boolean) => {
      if (val == true) {
        this.selectedCustomers = [];
        this.commonService.rowSelected(this.selectedCustomers);
      }
    });

  }

  disableRowSelectionSubscription() {
    this.commonService.disableRowSelectOnPanelEvent.subscribe((val: boolean) => {
      if (val == true) {
        this.disableRowSelection = true;
      }
      else {
        this.disableRowSelection = false;
      }
    });
  }

  // Content search.
  contentSearch(searchText: any) {
    if (searchText) {
      const request: ContentSearchDTO = {
        searchText: searchText,
        clientId: this.signOffMatrixRequest?.clientId ? this.signOffMatrixRequest?.clientId : this.parsedContext.clientId,
        engagementId: this.signOffMatrixRequest?.engagementId ? this.signOffMatrixRequest?.engagementId : "",
        taxpayerId: this.signOffMatrixRequest?.taxpayerId ? this.signOffMatrixRequest?.taxpayerId : "",
        workflowId: this.signOffMatrixRequest?.workflowId ? this.signOffMatrixRequest?.workflowId : ""
      };
      this.isSignOffMatrixDataLoading = true;
      this.commonService.buttonDisableForDataLoading(true);
      this.sharepointgraphService.contentSearch(request).subscribe({
        next: (response) => {
          if (response && response.length > 0) {
            let matchedCustomers = this.customers.filter(x => response.includes(x.key));
            this.filteredCustomers = matchedCustomers;
          } else {
            this.filteredCustomers = [];
          }
          this.isSignOffMatrixDataLoading = false;
          this.commonService.buttonDisableForDataLoading(false);
        },
        error: (error) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedSearch });
          console.error(error);
          this.isSignOffMatrixDataLoading = false;
        }
      });
    }
  }

  // On Search box change.
  onSearchChange(event: any) {
    if (event === "") {
      this.filteredCustomers = this.customers;
    }
  }


  getFileTypeIconClass(fileName: string): string {
    let fileExtension = fileName.split('.').pop() ?? '';
    const excelExtensions = ['xlsx', 'xls', 'xlsm', 'xlt', 'xltx', 'xltm'];
    const docExtensions = ['docx', 'doc', 'docm', 'dot', 'dotx', 'dotm'];
    const pptExtensions = ['pptx', 'ppt', 'pptm', 'pot', 'potx', 'potm'];

    if (excelExtensions.includes(fileExtension)) {
      return '../../assets/icons/excel.png';
    } else if (fileName.includes('.pdf')) {
      return '../../assets/icons/pdf.png';
    } else if (docExtensions.includes(fileExtension)) {
      return '../../assets/icons/word.png';
    } else if (pptExtensions.includes(fileExtension)) {
      return '../../assets/icons/ppt.png';
    } else if (fileName.includes('.vsdx')) {
      return '../../assets/icons/visio.png';
    } else if (fileName.includes('.msg')) {
      return '../../assets/icons/outlook.png';
    } else if (fileName.includes('.one')) {
      return '../../assets/icons/onenote.png';
    } else if (fileName.includes('.jpeg') || fileName.includes('.png')) {
      return '../../assets/icons/image.png';
    } else {
      return '../../assets/icons/file.png';
    }
  }

  // Download document.
  downloadDocument(document: any) {
    this.isSignOffMatrixDataLoading = true;
    Utilities.downloadDocument(document, this.sharepointgraphService, this.messageService);
    this.isSignOffMatrixDataLoading = false;
  }

  // Set reset subscriber.
  private setResetSpinner() {
    this.commonService.setResetSpinnerEvent.subscribe((val: boolean) => {
      this.isSignOffMatrixDataLoading = val;
    });
  }

  private saveGridInformationfromMatrix() {

   const documents = this.filteredCustomers.filter(x => this.uddChangeDocument.indexOf(x.key) > -1).map(x => { return { "documentId": x.key, "UserDefineDescription": x.userdefineddescription, "userEmailId": this.username }; })

    this.documentService.uddUpdateForDocuments(documents).subscribe({
      next: (x) => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: messages.updateSuccess });
        this.uddChangeDocument = [];
        this.saveCompleteEvent.emit(true);
      },
      error: (x) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: messages.failedUpdate });
        this.saveCompleteEvent.emit(false);
      }
    })
  }

  allowEdit(doc: any): boolean {
    if (!this.isEditMetadataAllowed) {
      return false;
    }

    if (!doc.isPrimary) {
      return false;
    }

    let allowEdit: boolean = doc?.url;
    
    if (doc?.statuscode == 'Archived' || this.isInvalidOperation(doc)) {
      if (!this.isApplicableTaxYearRequired) {
        allowEdit =  false;
      }
    }
    else if (doc?.statuscode == 'Unarchived' && this.isInvalidOperation(doc) === false) {
      allowEdit = true
    }

    return allowEdit;
  }

  isInvalidOperation(doc: any): boolean {
      if (doc.url === null || doc.url === '' || this.invalidOperationReasons.includes(doc.statuscode)) {
        return true;
      }
    return false;
  }

}
