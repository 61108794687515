<div>
  <div class="row">
    <p class="h5 m-1">Documents</p>
  </div>
  <p-toast></p-toast>
  <div class="card flex flex-wrap justify-content-center gap-5">
    <p-tree #tt [value]="files | truncatetreedata" class="w-full" selectionMode="single"
            [(selection)]="selectedFile" [filter]="false" [loading]="isTreeLoading"
            (onNodeSelect)="nodeSelect($event)" filterPlaceholder="Search Documents Tree">

      <ng-template pTemplate="header">
        <div class="ui-widget-header ui-corner-all ui-tree-filter-container">
          <span class="p-input-icon-right" style="width: 100%;">
            <i class="pi pi-search"></i>
            <input type="search" pInputText [(ngModel)]="treeval" style="width: 100%;"
                   placeholder="Search Documents Tree" (input)="filterTree([treeval],'labeltext')" />
          </span>

        </div>

        <div class="ui-widget-header ui-corner-all ui-tree-filter-container multiselect-width">
          <p-multiSelect [options]="engagementYears" placeholder="Select Engagement Year"
                         [style]="{'width':'100%', 'hieght':'35px', 'margin-top':'5px'}" class="w-full"
                         [(ngModel)]="selectedEngagementYears"
                         [showClear]="false" optionLabel="name"
                         (onChange)="filterTreeFromMultiSelect($event,'engagementtaxyear')">
          </p-multiSelect>
        </div>

        <div class="ui-widget-header ui-corner-all ui-tree-filter-container multiselect-width">
          <p-multiSelect [options]="workflowYears" placeholder="Select Workflow Year"
                         [style]="{'width':'100%', 'height':'35px', 'margin-top':'5px'}" class="w-full"
                         [(ngModel)]="selectedWorkflowYears"
                         [showClear]="false" optionLabel="name"
                         (onChange)="filterTreeFromMultiSelect($event,'workflowtaxyear')">
          </p-multiSelect>
        </div>

        <!-- Show only active engagements checkbox -->
        <div class="ui-widget-header ui-corner-all ui-tree-filter-container">
          <div style="margin-bottom: 10px;">
            <p-checkbox name="activeEngagements"
                        [style]="{'height':'30px', 'margin-top':'5px'}"
                        [binary]="true"
                        [(ngModel)]="showOnlyActiveEngagements"
                        label="Show only active engagements"
                        (onChange)="filterTreeBasedOnActiveEngagements($event)">
            </p-checkbox>
          </div>
          <p-checkbox name="activeWorkflows"
                      [style]="{'height':'30px', 'margin-top':'1px'}"
                      [binary]="true"
                      [(ngModel)]="showOnlyActiveWorkflows"
                      label="Show only active workflows"
                      (onChange)="filterTreeBasedOnActiveWorkflows($event)">
          </p-checkbox>
        </div>

      </ng-template>
    </p-tree>

  </div>
</div>


